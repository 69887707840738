//
//
//
//
//
//
//
//
//
//
//
//
//

import InfoPanel from '@/components/InfoPanel'
export default {
  name: 'DefaultLayout',
  components: { InfoPanel },
  data() {
    return {
      showCookieModal: false
    }
  },
  methods: {
    accept() {
      localStorage.setItem('cookie_accept', JSON.stringify(true))
      this.showCookieModal = false
    }
  },
  mounted() {
    const cookieAccept = localStorage.getItem('cookie_accept')
    if (!cookieAccept) this.showCookieModal = true
  }
}
