//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'InfoPanel',
  props: {
    value: { type: Boolean, default: false },
    title: { type: String, default: '' },
  },
}
