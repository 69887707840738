import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  SidebarNav: () => import('../..\\components\\SidebarNav.vue' /* webpackChunkName: "components/sidebar-nav" */).then(c => wrapFunctional(c.default || c)),
  TheFooter: () => import('../..\\components\\TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c)),
  TheHeader: () => import('../..\\components\\TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c)),
  InfoPanel: () => import('../..\\components\\InfoPanel\\index.vue' /* webpackChunkName: "components/info-panel" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
