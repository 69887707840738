import axios from 'axios'

/**
 * @class BaseApiService
 */
class BaseApiService {
  /**
   * @constructor
   */
  constructor() {
    this.client = axios.create({
      json: true,
      baseURL: process.env.API_BASE_URL,
    })
  }

  /**
   * @param {*} method
   * @param {string} url
   * @param {object} requestData
   * @param {object=} requestData.data
   * @param {object} requestData.params
   * @return {Promise<any|void>}
   */
  execute(method, url, { data = {}, params = {} }) {
    // const accessToken = store.getters['oidc/oidcAccessToken']
    const headers = {
      // 'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    return this.client({
      method,
      url,
      params,
      data,
      headers,
    }).then((res) => {
      return res.data
    }).catch((error) => {
      console.error(error)
      throw error
    })
  }

  /**
   * @param {String} url
   * @param {object} params
   * @return {Promise<* | void>}
   */
  get(url, params= {}) {
    return this.execute('get', url, { params })
  }

  /**
   * @param {String} url
   * @param {object} data
   * @param {object} params
   * @return {Promise<* | void>}
   */
  post(url, { data, params = {} }) {
    return this.execute('post', url, { data, params })
  }

  /**
   * @param {String} url
   * @param {object} data
   * @param {object} params
   * @return {Promise<*|void>}
   */
  put(url, { data = {}, params = {} }) {
    return this.execute('put', url, { data, params })
  }

  /**
   * @param {string} url
   * @param {object} params
   * @return {Promise<*|void>}
   */
  delete(url, params) {
    return this.execute('delete', url, { params })
  }
}

export default new BaseApiService()
