import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _24e47485 = () => interopDefault(import('..\\pages\\company\\aboutus.vue' /* webpackChunkName: "pages/company/aboutus" */))
const _d75d3b50 = () => interopDefault(import('..\\pages\\company\\contactus.vue' /* webpackChunkName: "pages/company/contactus" */))
const _24889575 = () => interopDefault(import('..\\pages\\company\\gdpr.vue' /* webpackChunkName: "pages/company/gdpr" */))
const _11bef8de = () => interopDefault(import('..\\pages\\company\\impressum.vue' /* webpackChunkName: "pages/company/impressum" */))
const _eb8a32a8 = () => interopDefault(import('..\\pages\\company\\jobs.vue' /* webpackChunkName: "pages/company/jobs" */))
const _41d01499 = () => interopDefault(import('..\\pages\\features\\for-enterprise.vue' /* webpackChunkName: "pages/features/for-enterprise" */))
const _79c2e9a9 = () => interopDefault(import('..\\pages\\features\\headless-cms.vue' /* webpackChunkName: "pages/features/headless-cms" */))
const _bbc9f740 = () => interopDefault(import('..\\pages\\features\\logging-and-monitoring.vue' /* webpackChunkName: "pages/features/logging-and-monitoring" */))
const _0ea64480 = () => interopDefault(import('..\\pages\\features\\messaging.vue' /* webpackChunkName: "pages/features/messaging" */))
const _55e3f8a4 = () => interopDefault(import('..\\pages\\features\\valuetracking.vue' /* webpackChunkName: "pages/features/valuetracking" */))
const _ac9f0f32 = () => interopDefault(import('..\\pages\\features\\why-to-use-konso.vue' /* webpackChunkName: "pages/features/why-to-use-konso" */))
const _3815d6cd = () => interopDefault(import('..\\pages\\jobs\\customer-support-manager.vue' /* webpackChunkName: "pages/jobs/customer-support-manager" */))
const _324b57b5 = () => interopDefault(import('..\\pages\\jobs\\talent-acquisition-specialist.vue' /* webpackChunkName: "pages/jobs/talent-acquisition-specialist" */))
const _25cd166b = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/company/aboutus",
    component: _24e47485,
    name: "company-aboutus"
  }, {
    path: "/company/contactus",
    component: _d75d3b50,
    name: "company-contactus"
  }, {
    path: "/company/gdpr",
    component: _24889575,
    name: "company-gdpr"
  }, {
    path: "/company/impressum",
    component: _11bef8de,
    name: "company-impressum"
  }, {
    path: "/company/jobs",
    component: _eb8a32a8,
    name: "company-jobs"
  }, {
    path: "/features/for-enterprise",
    component: _41d01499,
    name: "features-for-enterprise"
  }, {
    path: "/features/headless-cms",
    component: _79c2e9a9,
    name: "features-headless-cms"
  }, {
    path: "/features/logging-and-monitoring",
    component: _bbc9f740,
    name: "features-logging-and-monitoring"
  }, {
    path: "/features/messaging",
    component: _0ea64480,
    name: "features-messaging"
  }, {
    path: "/features/valuetracking",
    component: _55e3f8a4,
    name: "features-valuetracking"
  }, {
    path: "/features/why-to-use-konso",
    component: _ac9f0f32,
    name: "features-why-to-use-konso"
  }, {
    path: "/jobs/customer-support-manager",
    component: _3815d6cd,
    name: "jobs-customer-support-manager"
  }, {
    path: "/jobs/talent-acquisition-specialist",
    component: _324b57b5,
    name: "jobs-talent-acquisition-specialist"
  }, {
    path: "/",
    component: _25cd166b,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
