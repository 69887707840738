import ApiService from '~/services/ApiService'
import { STAT } from '~/apiEndpoint'

export default async function () {
  const data = {
    App: 'www',
    Action: 'view',
    Details:'page'
  }
  try {
    await ApiService.post(STAT, { data })
    console.log('route changed')
  } catch (e) {
    throw new Error(`${e}`)
  }
}
